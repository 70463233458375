@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap");

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f2f2f2;
  font-family: "Roboto", sans-serif;
  -webkit-tap-highlight-color: #0000001c;
}

html {
  height: 100%;
}

*:focus {
  outline: none !important;
}

:root {
  --safe-area-inset-top: env(safe-area-inset-top);
  --safe-area-inset-right: env(safe-area-inset-right);
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  --safe-area-inset-left: env(safe-area-inset-left);
}
